<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="row">
            <div class="col-1">
              <button type="button" class="btn btn-outline-warning mb-3" :disabled="statusRefresh == 0 ? true : false"
                @click="ListLp()">
                <i class="fas fa-undo-alt"></i>
              </button>
            </div>
            <div class="col-2">
              <button type="button" v-b-modal.modal-center class="btn btn-outline-warning waves-effect waves-light"
                data-toggle="modal" data-target=".bs-example-modal-center">
                Filter
              </button>
            </div>
          </div> 
        </div>
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-end">
            <label class="d-inline-flex align-items-center fw-normal">
              Search:
              <b-form-input v-model="filter" type="search" placeholder="Search..."
                class="form-control form-control-sm ms-2"></b-form-input>
            </label>
            <br />
            <b>Selected date range</b>: {{ selectedRangeLead }} Lead
          </div>
        </div>
        <!-- End search -->
      </div>
      <b-modal id="modal-center" centered title="Filter" title-class="font-18" hide-footer>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <label>Date Select</label>
              <flat-pickr class="form-control flatpickr-input" v-model="formData.date" :config="multipleOption"
                placeholder="date"></flat-pickr>
            </div>
            <!-- selectbox utm_source -->
            <div class="col-12">
              <label>utm_source</label>
              <b-form-select v-model="formData.utm_source" :options="uniqueUtmSources" class="form-control">
              </b-form-select>
            </div> 
            <!-- selectbox pipeline -->
            <div class="col-12">
              <label>Pipeline</label>
              <b-form-select v-model="formData.pipeline" :options="uniquePipeline" class="form-control">
              </b-form-select>
            </div>
            <div class="col-12 mt-4">
              <button class="btn btn-outline-info" @click="dateSend()">
                Gönder
              </button>
            </div>
          </div>
        </div>
      </b-modal>

      <div class="col-12">
        <div class="
              table table-centered
              datatable
              dt-responsive
              nowrap
              table-card-list
              dataTable
              no-footer
              dtr-inline
            ">
          <b-table table-class="table table-centered datatable table-card-list" thead-tr-class="bg-transparent"
            :items="orderData" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
            @filtered="onFiltered" :busy.sync="isLoading">
            <template v-slot:cell(id)="data">
              <a href="javascript: void(0);" class="text-body fw-bold">{{
      data.item.id
    }}</a>
            </template>

            <template v-slot:cell(name)="data">
              <a href="#" class="text-body">{{ data.item.name }}</a>
            </template>


            <template v-slot:cell(utm_source)="data">
              <a href="#" class="text-info" v-if="data.item.gtags">{{
      data.item.gtags.utm_source
    }}</a>
            </template>
            <template v-slot:cell(utm_campaign)="data">
              <a href="#" class="text-info" v-if="data.item.gtags">{{
      data.item.gtags.utm_campaign
    }}</a>
            </template>
            <template v-slot:cell(utm_medium)="data">
              <a href="#" class="text-info" v-if="data.item.gtags">{{
      data.item.gtags.utm_medium
    }}</a>
            </template>
            <template v-slot:cell(country)="data">
              <a href="#" class="text-body" v-if="data.item.country"
                style="margin-left:10px; top: -8px; position: relative;">{{ data.item.country }}</a>
            </template>
            <template v-slot:cell(ipcountry)="data">
              <a href="#" class="text-body" v-if="data.item.lead_info_find"
                style="margin-left:10px; top: -8px; position: relative;">{{ data.item.lead_info_find.country }}</a>
            </template>
            <template v-slot:cell(date)="data">
              <a href="#" class="text-body">{{ data.item.created_at }}</a>
            </template>
            <template v-slot:cell(detail)="data">
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <router-link class="px-2 text-primary" title="Edit" :to="'/crm-lead/detail/' + data.item.id">
                    <i class="uil uil-eye font-size-18"></i>
                  </router-link>
                </li>
              </ul>
            </template>
          </b-table>
        </div>
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center fw-normal">
              Show&nbsp;
              <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import moment from "moment";

import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// import { phone } from "phone";
// import CountryFlag from 'vue-country-flag'
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "CRM Leads",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    // CountryFlag,
    // Multiselect,
  },
  data() {
    return {
      title: "CRM Leads",
      items: [
        {
          text: "CRM Leads",
        },
        {
          text: "Orders",
          active: true,
        },
      ],
      orderData: [
        {
          name: "Dönüş Yok",
        },
      ],
      statusValue: 1,
      statusRefresh: 1,
      totalRows: 100,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      todayLead: null,
      yesterdayLead: null,
      selectedRangeLead: null,
      filterOn: [],
      sortBy: "age",
      isLoading: false,
      sortDesc: false,
      uniqueUtmSources: [],
      uniquePipeline: [],
      multipleOption: {
        mode: "range",
        maxDate: "today",
        disableMobile: "true",
      },
      formData: {
        user: "estetikInternationalLead",
        password: "777_leadEstetikInternationalYetki",
      },
      fields: [
        {
          key: "check",
          label: "",
        },
        {
          key: "lead_id",
          label: "ID ↑↓",
          sortable: true,
        },
        {
          key: "name",
          label: "Name ↑↓",
          sortable: true,
        },
        {
          key: "price",
          label: "Price ↑↓",
          sortable: true,
        },
        {
          key: "user_find.name",
          label: "User Name ↑↓",
          sortable: true,
        },
        {
          key: "pipeline_find.name",
          label: "Pipeline Name ↑↓",
          sortable: true,
        },
        {
          key: "status_find.name",
          label: "Status Name ↑↓",
          sortable: true,
        },
        {
          key: "loss_reason",
          label: "Loss Reason ↑↓",
          sortable: true,
        },
        {
          key: "closed_at",
          label: "Closed At ↑↓",
          sortable: true,
        },
        {
          key: "lead_created_at",
          label: "Created At ↑↓",
          sortable: true,
        },
        {
          key: "detail",
          label: "Detail",
        },
      ],
    };
  },

  computed: {
    rows() {
      return this.orderData.length;
    },
  },
  mounted() {
    this.totalRows = this.items.length;
    this.ListLp();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async dateSend() {
      var data = this.formData.date;
      const response = await API.Post(
        API.CRMLead.Filter,
        this.convertToFormData()
      );
      console.log(response.data);
      if (response.data.status === "success") {
        this.orderData = response.data.data;

        this.selectedRangeLead = response.data.data_count;
        response.data.data.forEach((element) => {
          element.created_at = moment(String(element.created_at)).format(
            "DD/MM/YYYY hh:mm"
          );
        });
        data = "Your work has been refresh";
        POPUP.popupClick(data);
        this.statusRefresh = 1;
      } else {
        data = "Your work could not be renewed";
        POPUP.popupClickNot(data);
        this.statusRefresh = 1;
      }
    },
    async ListLp() {
      this.isLoading = true;
      this.statusRefresh = 0;
      var data;
      const response = await API.Post(API.CRMLead.Index, this.convertToFormData());
      if (response.data.status === "yetkiYok") {
        this.statusRefresh = 0;
        var timer = 5000;
        data = "You don't have access permission";
        POPUP.popupClickNot(data, timer);
      }
      console.log(response.data);
      if (response.data.status === "success") {
        this.todayLead = response.data.todayLead;
        this.selectedRangeLead = 0;
        this.yesterdayLead = response.data.yesterdayLead;
        this.orderData = [];
        this.$nextTick(() => {
          this.orderData = response.data.data;
        });
        this.uniqueUtmSources = response.data.uniqueUtmSources;
        this.uniquePipeline = response.data.uniquePipeline;
        this.totalRows = response.data.total;

        data = "Your work has been refresh";
        POPUP.popupClick(data);
        this.statusRefresh = 1;
        this.formData.date = "date";
        this.formData.phoneCheckerValue = false;
        this.formData.todayChecker = false;
      } else {
        data = "Your work could not be renewed";
        POPUP.popupClickNot(data);
        this.statusRefresh = 1;
      }
    },

    timerPopup(timerClear = false) {
      let timerInterval;
      Swal.fire({
        position: "top-end",
        title: "Please wait to connect to CMS",
        timer: 3000,
        timerProgressBar: true,
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
      if (timerClear) {
        clearInterval(timerInterval);
      }
    },
  },
};
</script>
<style></style>
